<template>
  <v-card
    flat
  >
    <v-card-text>
      <v-form
        ref="form"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="contractName"
              :label="$vuetify.lang.t('$vuetify.contract.label')"
              :rules="rules.contract"
              append-icon="mdi-magnify"
              readonly
              @click="handleSearchClick"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="institutionHomeDoctor"
              :label="$vuetify.lang.t('$vuetify.patient.institutionAndDoctor')"
              :rules="rules.institutionHomeDoctor"
              append-icon="mdi-magnify"
              readonly
              @click="handleInstitutionDoctorClick"
              @click:clear="handleInstitutionDoctorClearClick"
            />
          </v-col>
          <v-col
            cols="12"
          >
            <batch-form :is-sign="true" @submit="handleSubmit"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-dialog
      v-model="dialogContractList"
      persistent
      max-width="800px"
    >
      <contract-list
        @submit="handleContractSubmit"
        @cancel="handleContractCancel"
      />
    </v-dialog>

    <v-dialog
      v-model="dialogInstitutionDoctor"
      persistent
      max-width="800px"
    >
      <institution-doctor
        @submit="handleInstitutionDoctorSubmit"
        @cancel="handleInstitutionDoctorCancel"
      />
    </v-dialog>

  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import dialogs from '@/services/dialogs.js';

  export default {
    components: {
      ContractList: () => import('./contract'),
      InstitutionDoctor: () => import('./InstitutionDoctor'),
      BatchForm: () => import('./BatchForm')
    },
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        dialogContractList: false,
        dialogInstitutionDoctor: false,
        contractSelected: null,
        institutionSelected: null,
        homeDoctorSelected: null,

        rules: {
          contract: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.patient.name')])
          ],
          institutionHomeDoctor: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.patient.institutionAndDoctor')])
          ]
        }
      }
    },

    computed: {
      ...mapGetters({
        regions: 'region/regions'
      }),

      contractName () {
        return this.contractSelected ? this.contractSelected.contractName : null
      },

      institutionHomeDoctor () {
        return this.institutionSelected && this.homeDoctorSelected ? `${ this.institutionSelected.showName } - ${ this.homeDoctorSelected.realName }` : null
      }
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions({
        patientImportSign: 'patient/importSign'
      }),

      initialize () {
      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.$nextTick ( () => {
          this.$refs.form.resetValidation()
        })

      },

      handleCancel() {
        this.$emit('cancel', true)
      },

      handleSubmit(data) {
        if ( !this.$refs.form.validate() ) {
          return false
        }

        const params = {}
        for (let key in data) {
          if (key !== 'uploadFile') {
            params[key] = data[key]
          }
        }
        params.contractId = this.contractSelected.id
        params.institutionId = this.institutionSelected.id
        params.doctorId = this.homeDoctorSelected.id
        const formData = new FormData()
        formData.append('file', data.uploadFile)
        this.patientImportSign( { params: params , data: formData} ).then( (data) => {
          if (data.data.successNum === 0) {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'error' })
          } else if (data.data.failedNum === 0) {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'success' }, () => this.$router.push( { name: 'patientList' }))
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.importTooltips', data.data.successNum, data.data.failedNum), { duration: 10, type: 'warning' })
          }
        }).catch( () => {
          dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
        })
      },

      handleSearchClick () {
        this.dialogContractList = true
      },

      handleClearClick () {

      },

      handleContractSubmit (data) {
        this.contractSelected = data
        this.dialogContractList = false
      },

      handleContractCancel () {
        this.dialogContractList = false
      },

      handleInstitutionDoctorSubmit ( data ) {
        this.institutionSelected = data.institution
        this.homeDoctorSelected = data.homeDoctor
        this.dialogInstitutionDoctor = false
      },

      handleInstitutionDoctorCancel () {
        this.dialogInstitutionDoctor = false
      },

      handleInstitutionDoctorClick () {
        this.dialogInstitutionDoctor =  true
      },

      handleInstitutionDoctorClearClick () {

      }

    }
  }
</script>
